module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Myshoots","short_name":"Myshoots","start_url":"/","background_color":"#FF3366","theme_color":"#FF3366","display":"minimal-ui","icon":"src/images/myshoots.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"95a5ddefd3fd3faa67052c871956aa88"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-87356110-13","head":false,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1775301312717390"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
